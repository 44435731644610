<template>
    <div>
        <div class="tab_bar">
            <nav class="footer_nav">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <ul>
                                <MenuItem 
                                    v-for="link in routersList"
                                    :key="link.name"
                                    :link=link />
                                <li class="truncate footer_item">
                                    <router-link 
                                        class="link"
                                        :to="{name: 'menu'}">
                                        <div class="icon">
                                            <i class="fi fi-rr-apps"></i>
                                        </div>
                                        <div class="label truncate">
                                            {{ $t('menu') }}
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <footer class="clearfix footer"></footer>
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
export default {
    components: {
        MenuItem
    },
    computed: {
        routersList() {
            return [...this.$store.state.navigation.routerList].filter(f => f.isShowMobile && f.isFooter)
        }
    }
}
</script>


<style scoped lang="scss">
.footer{
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: calc(var(--footerHeight) + var(--safe-area-inset-bottom));
}
.tab_bar {
  z-index: 800;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  position: relative;
  height: auto;
}
.footer_nav{
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    min-height: var(--footerHeight);
    text-align: center;
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: calc(var(--safe-area-inset-bottom));
    background: #fff;
    box-shadow: var(--footerShadow);
    box-sizing: border-box;
    will-change: transform;
    transform: translateZ(0);
    transition: transform .2s linear,height .12s,background-color .3s ease-in-out;
    &::v-deep{
        .footer_item {
            list-style: none;
            position: relative;
            width: 100%;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none;   /* Chrome/Safari/Opera */
            -khtml-user-select: none;    /* Konqueror */
            -moz-user-select: none;      /* Firefox */
            -ms-user-select: none;       /* Internet Explorer/Edge */
            user-select: none;  
            .link_badge{
                position: absolute;
                top: -5px;
                right: -13px;
                .ant-badge-count{
                    line-height: 16px;
                    min-width: 16px;
                    height: 16px;
                    font-size: 10px;
                    padding: 0 5px;
                    &.ant-badge-multiple-words{
                        padding: 0 5px;
                    }
                }
            }
            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                i{
                    font-size: 1.3rem;
                    line-height: 1;
                }
            }
            .label {
                font-size: 11px;
                width: 100%;
                margin-top: 2px;
                font-weight: 300;
                color: var(--footerLink);
                transition: color .3s ease-in-out;
            }
            .link {
                color: #354052;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                padding-bottom: 5px;
                padding-top: 5px;
                position: relative;
            }
            .router-link-exact-active {
                color: var(--blue);
            }
        }
    }
};
.row {
    display: flex;
    flex-wrap: wrap;
}
.col-12{
    flex: 0 0 100%;
    max-width: 100%;
}
.tab_bar .footer_nav ul {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
</style>
